<template>
  <div>
    <div
      v-if="isShowListNote === 0"
      style="
        width: 100%;
        bottom: 80px;
        position: absolute;
        border-bottom: 1px solid #e6e6e6;
      "
      class="bottom-navbarUI customButtonChaAndNote"
    >
      <div
        class="row justify-content-center"
        style="bottom: 5px; left: 110px; right: 0"
      >
        <b-button
          v-on:click.prevent="selectScreen(1)"
          :disabled="isLoading"
          variant="info"
          class="mr-4"
          style="background-color: #2eb85c; border-color: #2eb85c"
          >ノート</b-button
        >
        <b-button
          v-on:click.prevent="selectScreen(2)"
          :disabled="isLoading"
          variant="info"
          style="background-color: #2eb85c; border-color: #2eb85c"
          >質問する</b-button
        >
      </div>
    </div>
    <div
      v-if="isShowListNote === 1"
      style="width: 100%; bottom: 80px; position: absolute"
    >
      <div
        class="bottom-navbar"
        style="height: 90%; background: #fff; z-index: 1020 !important"
        v-if="isCreenMobile === 1"
      >
        <h2
          class="sidebarTtl mb-0 d-flex justify-content-between px-4"
          v-bind:class="{
            marginModalNote: $route.params.idContent !== undefined,
          }"
          v-if="$route.params.idContent !== undefined"
        >
          <div>
            <a
              data-obj-name="mokuji_note_link"
              class="toList"
              href="/note-content"
              style="font-size: 14px; color: black"
              >ノート</a
            >
          </div>
          <div>
            <a
              data-obj-name="mokuji_add_note_link"
              class="addNote btnNote"
              data-type="normal"
              style="font-size: 14px; color: black"
              @click="setDataModal()"
              >追加する<img
                src="@/assets/img/icon_note.png"
                style="margin-left: 4%; width: 26%"
            /></a>
          </div>
        </h2>
        <div
          class="d-flex justify-content-center"
          style="
            z-index: 3000;
            background-color: rgb(255 255 255 / 0%) !important;
            position: relative;
            top: 3%;
          "
        ></div>
        <div
          class="w-100"
          v-bind:class="{
            checkPopupContent: $route.params.idContent !== undefined,
          }"
          style="overflow: auto; height: 481px; background: #92a9d3"
        >
          <div class="list-note">
            <div
              class="list-note-item my-2 position-relative customListNote"
              v-for="(item, index) in listNote"
              :key="index"
            >
              <div
                class="list-note-item-top my-2 d-flex align-items-center justify-content-between"
              >
                <div
                  class="title-item"
                  style="
                    word-break: break-all;
                    padding-right: 3%;
                    font-weight: 100;
                  "
                >
                  {{ moment(new Date(item.created_at)).format("YYYY/MM/DD") }}
                </div>
                <div class="d-flex align-items-center">
                  <div class="list-note-button">
                    <img
                      src="@/assets/img/icon_pencil.png"
                      class="icon-note mr-3"
                      @click="showModalEdit(item.id)"
                      style="width: 20px; height: 20px"
                    />
                    <img
                      src="@/assets/img/icon_delete.png"
                      class="icon-note"
                      v-on:click="showConfirm1(item.id)"
                      v-b-modal.modal-delete-note-moblile
                      style="width: 20px; height: 20px"
                    />
                  </div>
                </div>
              </div>
              <div
                class="list-note-item-content d-flex"
                style="font-size: 16px; font-weight: bold"
              >
                {{ item.title_note }}
              </div>
              <div
                class="list-note-item-content d-flex customContentNoteMobile"
                style="word-break: break-all; font-size: 14px"
              >
                <div
                  v-html="item.content_note.replaceAll('\n', '<br />')"
                ></div>
              </div>
              <div
                class="list-note-item-pdf d-flex flex-column"
                style="font-size: 14px; font-weight: 100"
              >
                <!-- <div>コンテンツ： {{ item.page_title }}</div> -->
                <div>
                  PDF：{{ item.page_pdf === null ? item.page_pdf : 0 }}枚目
                </div>
                <div>
                  動画：{{
                    item.seconds_watch_video !== null
                      ? item.seconds_watch_video
                      : 0
                  }}秒
                </div>
              </div>
              <div
                class="list-tag d-flex flex-wrap my-3 align-items-center"
                v-if="item.tags.length > 0"
              >
                <div
                  class="position-relative d-flex align-items-center px-1 pt-1"
                  v-for="(item, index) in item.tags"
                  :key="index"
                >
                  <a class="customA"
                    ><span class="customSpan">{{ item.tag_name }}</span></a
                  ><img
                    src="@/assets/img/bg_note_tag_active.png"
                    style="width: 18px; height: 20px"
                  />
                </div>
                &ensp;&ensp;
              </div>
            </div>
          </div>
        </div>
        <div
          style="
            height: 80px;
            background: #f9f9f9;
            border-bottom: 1px solid #e6e6e6;
          "
          class="d-flex align-items-center"
        >
          <div class="row row-input w-100 ml-0 mt-3">
            <div
              class="col pl-2 pr-0 h-100"
              style="text-align: center"
              @click="backBottom()"
            >
              <span style="text-align: center"
                ><img
                  src="@/assets/img/logout.png"
                  style="margin-left: 4%"
                  class="px-2"
                />
                <div
                  style="
                    text-align: center;
                    font-size: 10px;
                    text-align: center;
                  "
                >
                  閉 じ る
                </div>
              </span>
            </div>
            <input
              class="form-control col-7"
              v-model="contentIcon"
              placeholder="質問や学んだ内容などを記入"
              style="
                border: 1px solid #fff;
                border-radius: 10px;
                background: #e9e9e9;
              "
            />
            <div
              class="col"
              style="text-align: center"
              @click="createNoteIcon()"
            >
              <span style="text-align: center"
                ><img
                  src="@/assets/img/note.png"
                  style="margin-left: 4%"
                  class="px-2"
                />
                <div
                  style="
                    text-align: center;
                    font-size: 10px;
                    text-align: center;
                  "
                >
                  ノ ー ト 記録
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bottom-navbar"
        style="height: 90%; background: #fff"
        v-bind:class="{
          zindexListChat: isShowDetailChat === 0,
          zindexDetailChat: isShowDetailChat === 1,
        }"
        v-if="isCreenMobile === 2"
      >
        <div
          class="d-flex justify-content-center"
          style="z-index: 3000; position: relative; top: 3%"
          v-if="Object.keys(noteByContent).length > 0"
        >
          <div class="customContentMoblie">
            <div class="row mx-0">
              <div class="col-3 px-1">
                <img
                  v-if="noteByContent.image_url"
                  :src="env + noteByContent.image_url"
                  width="70px"
                  height="70px"
                />
                <img
                  v-else-if="
                    noteByContent.image_url == null ||
                    noteByContent.image_url == ''
                  "
                  src="@/assets/img/no-image.jpg"
                  width="70px"
                  height="70px"
                />
              </div>
              <div class="col-auto">
                <div>{{ listNote.title }}</div>
                <div class="mt-3">{{ noteByContent.page_title }}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="w-100"
          v-bind:class="{
            checkPopupContent: $route.params.idContent !== undefined,
          }"
          style="overflow: auto"
          :style="[
            {
              height:
                isShowDetailChat === 0 ? '681px!important' : '581px!important',
            },
          ]"
        >
          <!--<div style="height: 100px; background-color: #39f">
            <div class="customTitleContent">{{ listChat.data[0].title }}</div>
          </div>-->
          <div class="list-note">
            <div v-if="isShowDetailChat === 0">
              <div
                class="list-note-item my-2 position-relative customTitle"
                v-for="(item, index) in listChat.data"
                :key="index"
                @click="getDetailMessage(item)"
              >
                <div
                  class="list-note-item-top my-2 d-flex align-items-center justify-content-between"
                >
                  <div
                    class=""
                    style="word-break: break-all; padding-right: 3%"
                  >
                    作成日:{{
                      moment(new Date(item.created_at)).format("YYYY/MM/DD")
                    }}
                  </div>
                </div>
                <div
                  class="list-note-item-content"
                  style="font-size: 16px; font-weight: bold"
                >
                  <!-- <img
                        :src="env + item.image"
                        style="width: 18px; height: 20px"
                      /> -->
                  <div class="row mx-0">
                    <div class="col-2 px-0">
                      <img
                        v-if="item.image"
                        :src="env + item.image"
                        width="80px"
                        height="80px"
                      />
                      <img
                        v-else-if="item.image === null || item.image === ''"
                        src="@/assets/img/no-image.jpg"
                        width="70px"
                        height="70px"
                      />
                    </div>
                    <div class="col-4 px-0">
                      <div class="mt-3">{{ item.title }}</div>
                    </div>
                    <div class="col-4 px-0">
                      <div
                        class="mt-3"
                        style="
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        への{{ item.message }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex justify-content-center position-relative"
                v-if="listChat.total"
              >
                <CPagination
                  v-if="listChat.total"
                  :activePage.sync="page"
                  :pages="listChat.last_page"
                  align="center"
                />
              </div>
            </div>
            <div v-if="isShowDetailChat === 1">
              <div
                class=""
                style="
                  background-color: #668ad8b8;
                  overflow: auto;
                  height: calc(100vh - (140px + 111px));
                  position: relative;
                "
              >
                <div
                  class=""
                  style="padding: 10px 12px"
                  v-for="(itemMess, index) in listMessageByChatID.message"
                  v-bind:key="index"
                >
                  <!-- UI Message ADMIN -->
                  <div
                    v-if="itemMess.user_type !== 4 && itemMess.user_type !== 5"
                    class="d-flex flex-nowrap align-items-center"
                  >
                    <div class="customChatAdmin mt-3">
                      <div class="d-flex flex-column p-3">
                        <div style="word-wrap: break-word">
                          {{ itemMess.message }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End UI Message ADMIN -->

                  <!-- UI Message USER -->
                  <div
                    class="d-flex flex-nowrap flex-row-reverse align-items-center"
                    v-if="itemMess.user_type === 4 || itemMess.user_type === 5"
                  >
                    <div class="customChatUser mt-3">
                      <div class="d-flex flex-column p-3">
                        <div style="word-wrap: break-word">
                          {{ itemMess.message }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="pr-3 mt-3"
                      v-if="itemMess.id !== listMessageByChatID.message[0].id"
                      @click="deletMessage(itemMess.id)"
                    >
                      <i class="fas fa-times" style="color: white"></i>
                    </div>
                  </div>
                  <!-- End UI Message USER -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style="
            height: 80px;
            background: #f9f9f9;
            border-bottom: 1px solid #e6e6e6;
          "
          class="d-flex align-items-center"
          v-if="isShowDetailChat === 1"
        >
          <div
            class="row row-input w-100 ml-0 mt-3 d-flex justify-content-center align-items-center"
          >
            <div
              class="col pl-2 pr-0 h-100"
              style="text-align: center"
              @click="backListMenu()"
            >
              <span style="text-align: center"
                ><img
                  src="@/assets/img/logout.png"
                  style="margin-left: 4%"
                  class="px-2"
                />
                <div
                  style="
                    text-align: center;
                    font-size: 10px;
                    text-align: center;
                  "
                >
                  閉 じ る
                </div>
              </span>
            </div>
            <textarea
              class="hoverMouse pl-0 px-4"
              v-model="titleSendMessage"
              placeholder="返信内容を記載1"
              style="
                border: 1px solid #fff;
                width: 70%;
                background-color: #d8dbe0;
                border-radius: 30px;
                padding-bottom: 3px;
                resize: none;
              "
              rows="3"
              no-resize
              @keydown.enter.exact.prevent="titleSendMessage += '\n'"
              @keydown.enter.shift.exact.prevent="ClickSendMessage()"
              @keyup="checkLimitLength"
            ></textarea>
            <div
              class="col"
              style="text-align: center"
              @click="ClickSendMessage()"
            >
              <span style="text-align: center"
                ><img
                  src="@/assets/img/send.png"
                  style="margin-left: 4%"
                  class="px-2"
                />
                <div
                  style="
                    text-align: center;
                    font-size: 10px;
                    text-align: center;
                  "
                >
                  質問する
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-delete-note-moblile" hide-header hide-footer>
      <div class="d-block text-center">
        <h5>
          削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
        </h5>
      </div>
      <div class="d-flex justify-content-center">
        <b-button
          class="mx-2 mt-3 btn btn-danger"
          block
          @click="removeNoteMoblie(dataModal)"
          >削除</b-button
        >
        <b-button
          class="mx-2 mt-3 btn btn-secondary"
          block
          @click="$bvModal.hide('modal-delete-note')"
        >
          キャンセル
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="modal-create-note-mobile1"
      modal-class="modalCustom px-3"
      style="min-width: 720px"
    >
      <template #modal-header="{}">
        <h5 class="w-100" style="text-align: center">ノート</h5>
        <b-button
          size="sm"
          style="background-color: white; border-color: white"
          class="close"
          @click="closeModal()"
        >
          <i class="fas fa-times"></i>
        </b-button>
      </template>
      <CRow
        ><CCol sm="12">
          <input
            class="form-control hoverMouse"
            v-model="title"
            placeholder="タイトル"
            style="border: 1px solid #fff; height: 66px"
            :maxlength="50"
          /> </CCol
        ><CCol sm="12" class="pt-3">
          <textarea
            class="form-input col-sm-12 p-3 hoverMouse"
            type="text"
            v-model="content"
            placeholder="メモ"
            rows="4"
            style="border: 1px solid #fff"
            :maxlength="1000"
          /></CCol
        ><CCol sm="12" class="my-3">
          <!-- <div>コンテンツ：{{ detail_page_title }}</div> -->
          <div class="d-flex my-2">PDF：{{ page_pdf }} 枚目</div>
          <div class="d-flex my-2">動画：{{ seconds_watch_video }} 秒</div>
        </CCol>
        <CCol sm="12">
          <input
            class="form-control hoverMouse"
            v-model="tag"
            placeholder="登録するタグ名を入力し[Enter]"
            style="border: 1px solid #fff; height: 64px"
            v-on:keyup.enter="createTag"
          /> </CCol
        ><CCol sm="12" class="pt-3">
          <span class="title2">よく使うタグ</span>
          <div class="list-tag d-flex flex-wrap my-3 align-items-center">
            <div
              class="position-relative d-flex align-items-center px-1 py-1"
              v-for="(item, index) in listTagOptions"
              :key="index"
            >
              <a
                class="customTag2"
                :class="{ customA: item.checked === true }"
                v-on:click="ClickTagChecked(item, index)"
                ><span class="customSpan">{{ item.tag_name }}</span></a
              ><img
                v-if="item.checked === true"
                v-on:click="ClickTagChecked(item, index)"
                src="@/assets/img/bg_note_tag_active.png"
                style="width: 18px; height: 20px; cursor: pointer"
              />
              <img
                v-else
                v-on:click="ClickTagChecked(item, index)"
                src="@/assets/img/bg_note_tag.png"
                style="width: 18px; height: 20px; cursor: pointer"
              />
            </div>
            &ensp;&ensp;
          </div>
        </CCol></CRow
      >
      <template #modal-footer="{}">
        <div class="">
          <b-button
            v-on:click.prevent="CreateNewNote1()"
            :disabled="isLoading"
            variant="info"
            class="mr-4"
            >ノート記録
          </b-button>
          <b-button
            variant="secondary"
            @click="closeModal()"
            class="mx-2 back-btn"
            >戻る</b-button
          >
        </div>
      </template>
    </b-modal>
    <b-modal
      id="modal-edit-note-mobile1"
      modal-class="modalCustom px-3"
      style="min-width: 720px"
    >
      <template #modal-header="{}">
        <h5 class="w-100" style="text-align: center">ノート</h5>
        <b-button
          size="sm"
          style="background-color: white; border-color: white"
          class="close"
          @click="closeModalEdit()"
        >
          <i class="fas fa-times"></i>
        </b-button>
      </template>
      <CRow
        ><CCol sm="12">
          <input
            class="form-control hoverMouse"
            v-model="detail_title"
            placeholder="タイトル"
            style="border: 1px solid #fff; height: 66px"
            :maxlength="50"
          /> </CCol
        ><CCol sm="12" class="pt-3">
          <textarea
            class="form-input col-sm-12 p-3 hoverMouse"
            type="text"
            v-model="detail_content"
            placeholder="メモ"
            rows="4"
            style="border: 1px solid #fff"
            :maxlength="1000"
          />
        </CCol>
        <CCol sm="12" class="my-3">
          <!-- <div>コンテンツ：{{ detail_page_title }}</div> -->
          <div class="d-flex my-2">PDF：{{ detail_pdf }} 枚目</div>
          <div class="d-flex my-2">動画：{{ detail_video }} 秒</div></CCol
        >
        <CCol sm="12">
          <input
            class="form-control hoverMouse"
            v-model="tag"
            placeholder="登録するタグ名を入力し[Enter]"
            style="border: 1px solid #fff; height: 64px"
            v-on:keyup.enter="createTag"
          /> </CCol
        ><CCol sm="12" class="pt-3">
          <span class="title2">よく使うタグ</span>
          <div class="list-tag d-flex flex-wrap my-3 align-items-center">
            <div
              class="position-relative d-flex align-items-center px-1 py-1"
              v-for="(item, index) in listTagOptions"
              :key="index"
            >
              <a
                class="customTag2"
                :class="{ customA: item.checked === true }"
                v-on:click="ClickTagChecked(item, index)"
                ><span class="customSpan">{{ item.tag_name }}</span></a
              ><img
                v-if="item.checked === true"
                v-on:click="ClickTagChecked(item, index)"
                src="@/assets/img/bg_note_tag_active.png"
                style="width: 18px; height: 20px; cursor: pointer"
              />
              <img
                v-else
                v-on:click="ClickTagChecked(item, index)"
                src="@/assets/img/bg_note_tag.png"
                style="width: 18px; height: 20px; cursor: pointer"
              />
            </div>
            &ensp;&ensp;
          </div>
        </CCol></CRow
      >
      <template #modal-footer="{}">
        <div class="">
          <b-button
            v-on:click.prevent="editNote()"
            :disabled="isLoading"
            variant="info"
            class="mr-4"
            >完了
          </b-button>
          <b-button
            variant="secondary"
            @click="closeModalEdit()"
            class="mx-2 back-btn"
            >戻る</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import moment from "moment";
import { eventBus } from "../../main";
import { Constants } from "../../utils/constants";
import { Api } from "../../utils/http-common";
import { Urls } from "../../utils/urls";
export default {
  name: "DetailPageByUINote",
  data() {
    return {
      isLoading: false,
      idContent: null,
      dataCata: null,
      titleCata: "",
      idCata: null,
      title: "",
      content: "",
      tag: "",
      seconds_watch_video: 0,
      page_pdf: 0,
      openNote: false,
      dataModal: "",
      tag_id: "",
      tags: [],
      listTagOptions: [],
      selectedTag: [],
      acceptPDF: false,
      acceptVideo: false,
      detailNote: {},
      detail_title: "",
      detail_content: "",
      detail_video: "",
      detail_pdf: 0,
      isAdded: 0,
      listCata: [],
      idPageNext: 0,
      idPagePrev: 0,
      detail_page_title: "",
      currentTimeYoutu: 0,
      currentPagePdf: 1,
      isListNote: 0,
      isCategoryTitle: 0,
      isSelect: null,
      idURL: this.$route.params.idContent,
      isTagChecked: [],
      ind: null,
      isShow: false,
      valueChat: null,
      contentIcon: null,
      env: process.env.VUE_APP_ROOT_BACKEND + "/storage/",
      isShowListNote: 0,
      isCreenMobile: 1,
      isShowDetailChat: 0,
      page: 1,
      titleSendMessage: null,
      idChat: null,
      listNote: null,
      idPageView: parseInt(this.$route.params.idPage),
      idChapterView: parseInt(this.$route.params.idChapter),
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      previousTitleSendMessage: null,
    };
  },
  //   components: { VueSlickCarousel },
  async created() {
    const { params } = this.$route;
    this.idCata = params.idPage;
    await this.getCatalogue({
      id: params.idPage,
      content_id: this.$route.params.idContent,
    });
    // if (this.IdContent === null) {
    //   this.idContent = params.idContent;
    //   this.getContentLibraryById({
    //     id: params.idContent,
    //     shop_id: params.shopId,
    //   });
    //   await this.getListNote(params.idContent);
    // } else if (this.IdContent !== null) {
    //   this.idContent = this.IdContent;
    //   this.getContentLibraryById(this.IdContent);
    //   this.getListNote(this.IdContent);
    // }
    const shop_id = this.shop_id;
    await this.getListTag(shop_id);
    const interval = setInterval(function () {
      if (document.getElementById("disable-right-click")) {
        document
          .getElementById("disable-right-click")
          .addEventListener("contextmenu", (event) => event.preventDefault());
        clearInterval(interval);
      }
    }, 50);
    document.addEventListener("contextmenu", (event) => event.preventDefault());
  },
  computed: {
    ...mapGetters([
      "isShowButtonChatOrNote",
      "IdContent",
      "listChat",
      "listMessageByChatID",
      "isShowNote",
      "noteByContent",
      "listTag",
      "noteById",
    ]),
  },
  watch: {
    contentLibraryById() {
      if (this.contentLibraryById && this.contentLibraryById.length > 0) {
        this.dataCata = this.contentLibraryById[0].catalogue.filter(
          (item) => item.id == this.idCata
        );
        this.listCata = this.contentLibraryById[0].catalogue;

        if (this.dataCata[0]) {
          this.titleCata = this.dataCata[0].title;
        }

        this.idPageNext = Number(this.idCata) + 1;
        this.idPagePrev = Number(this.idCata) - 1;
      }
    },

    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
        this.isLoading = false;
        this.getListTag(this.shop_id);
      }
    },

    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
        this.isLoading = false;
      }
    },

    listTag() {
      this.listTagOptions = this.listTag.map((item) => ({
        tag_name: item.tag_name,
        tag_id: item.id,
      }));
      if (this.selectedTag.length > 0) {
        this.listTagOptions.forEach((element) => {
          this.selectedTag.forEach((item) => {
            if (element.tag_name === item.tag_name) {
              this.$set(element, "checked", true);
            }
          });
        });
      }
    },
    noteById() {
      if (this.noteById) {
        this.detailNote = this.noteById;
        this.detail_title = this.detailNote.title_note;
        this.detail_content = this.detailNote.content_note;
        this.detail_video = this.detailNote.seconds_watch_video;
        this.detail_pdf = this.detailNote.page_pdf;
        this.selectedTag = this.noteById.tags;
        this.detail_page_title = this.noteById.page_title;
        if (this.selectedTag.length > 0) {
          this.listTagOptions.forEach((element) => {
            this.selectedTag.forEach((item) => {
              if (element.tag_name === item.tag_name) {
                this.$set(element, "checked", true);
              }
            });
          });
        }
      }
    },
    page() {
      this.getListChats(this.page);
    },
    listMessageByChatID() {
      this.idChat = this.listMessageByChatID.id;
    },
    isShowNote() {
      if (this.isShowNote === true) {
        this.isShowListNote = 1;
        this.isCreenMobile = 1;
      }
    },
  },
  methods: {
    moment,
    ...mapActions({
      getListNote: "getListNote",
      getCatalogue: "getCatalogue",
      getContentLibraryById: "getContentLibraryById",
      createNote: "createNote",
      deleteNote: "deleteNote",
      getNoteByIdUser: "getNoteByIdUser",
      updateNoteById: "updateNoteById",
      getListTag: "getListTag",
      createNewTag: "createNewTag",
      getListChat: "getListChat",
      getMessageByChatID: "getMessageByChatID",
      sendMessage: "sendMessage",
      deleteMessageByID: "deleteMessageByID",
    }),
    async getListNoteByPages() {
      const dataRequest = {
        shop_id: parseInt(this.shop_id),
        content_id: parseInt(this.$route.params.idContent),
        catalogue_id: parseInt(this.$route.params.idChapter),
        page_id: parseInt(this.$route.params.idPage),
      };
      await Api.userRequestServer
        .post(`/${Urls.PAGE}/${Urls.NOTE}`, dataRequest)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            this.listNote = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async CreateNewNote1() {
      if (this.title === "") {
        this.$toasted.error("ノートタイトルは入力必須項目です。");
      } else if (this.content == "") {
        this.$toasted.error("コンテンツノートは入力必須項目です。");
      } else {
        this.isLoading = true;
        let shop_id = this.shop_id;
        const { params } = this.$route;
        let listTag = this.selectedTag.map((item) => item.tag_id);
        const formData = {
          shop_id: shop_id,
          content_id: parseInt(params.idContent),
          page_id: parseInt(this.idPageView),
          catalogue_id: parseInt(this.idChapterView),
          tag_id: listTag,
          title_note: this.title,
          content_note: this.content,
          page_pdf: parseInt(this.page_pdf),
          seconds_watch_video: this.seconds_watch_video,
          json_pdf: localStorage.getItem("currentPagePDF"),
          json_video:
            localStorage.getItem("currentTimeYoutu") != null
              ? localStorage.getItem("currentTimeYoutu")
              : localStorage.getItem("currentTimeVimeo"),
        };
        await this.createNote(formData);
        const dataRequest = {
          shop_id: parseInt(this.shop_id),
          content_id: parseInt(this.$route.params.idContent),
          catalogue_id: parseInt(this.idChapterView),
          page_id: parseInt(this.idPageView),
        };
        this.getListNoteByPages(dataRequest);
        this.title = "";
        this.content = "";
        // this.page_pdf = "";
        // this.seconds_watch_video = "";
        this.selectedTag = [];
        this.acceptPDF = false;
        this.acceptVideo = false;
        // const dataRequest = {
        //   shop_id: parseInt(this.$route.params.shopId),
        //   content_id: parseInt(this.$route.params.idContent),
        //   catalogue_id: parseInt(this.idChapterView),
        //   page_id: parseInt(this.idPageView),
        // };
        this.getListNoteBypage(dataRequest);
        // this.getListNote(this.idContent);
        this.$nextTick(() => {
          this.$bvModal.hide("modal-create-note-mobile1");
          this.isLoading = false;
        });
      }
    },
    async editNote() {
      if (this.detail_title === "") {
        this.$toasted.error("ノートタイトルは入力必須項目です。");
      } else if (this.detail_content == "") {
        this.$toasted.error("コンテンツノートは入力必須項目です。");
      } else {
        this.isLoading = true;
        let shop_id = this.shop_id;
        let listTag = this.selectedTag.map((item) => item.tag_id);
        const formData = {
          shop_id: shop_id,
          id: this.detailNote.note_id,
          content_id: this.detailNote.content_id,
          page_id: this.detailNote.page_id,
          tag_id: listTag,
          title_note: this.detail_title,
          content_note: this.detail_content,
          page_pdf: this.detail_pdf,
          seconds_watch_video: this.detail_video,
          status: 0,
        };
        await this.updateNoteById(formData);
        const dataRequest = {
          shop_id: parseInt(this.shop_id),
          content_id: parseInt(this.$route.params.idContent),
          catalogue_id: parseInt(this.idChapterView),
          page_id: parseInt(this.idPageView),
        };
        this.getListNoteByPages(dataRequest);
        this.$nextTick(() => {
          this.$bvModal.hide("modal-edit-note-mobile1");
          this.isLoading = false;
        });
      }
    },
    removeNoteMoblie(id) {
      const formData = {
        id: id,
        shop_id: this.shop_id,
      };
      this.deleteNote(formData);
      const dataRequest = {
        shop_id: parseInt(this.shop_id),
        content_id: parseInt(this.$route.params.idContent),
        catalogue_id: parseInt(this.idChapterView),
        page_id: parseInt(this.idPageView),
      };
      this.getListNoteByPages(dataRequest);
      this.$nextTick(() => {
        this.$bvModal.hide("modal-delete-note-moblile");
      });
    },
    isImage(url) {
      return /\.(jpg|jpeg|png|svg|JPG|JPEG|PNG)$/.test(url);
    },
    returnPage(url, indexType, page_title) {
      if (indexType == null) indexType = this.type;
      this.type = indexType;
      const request = {
        shop_id: this.shop_id,
        type: this.type,
      };
      this.getBottomSettingType(request);
      this.getUnreadContent(request);
      if (page_title == "メニュー") {
        this.$store.commit("set", ["showMenu", !this.showMenu]);
      } else {
        this.$router.push(url);
        this.$store.commit("set", ["showMenu", false]);
      }
    },
    setDataModal() {
      this.tag = "";
      this.title = "";
      this.content = "";
      const shop_id = this.shop_id;
      this.getListTag(shop_id);
      this.selectedTag = [];
      this.$bvModal.show("modal-create-note-mobile1");
    },
    closeModal() {
      this.$bvModal.hide("modal-create-note-mobile1");
      this.selectedTag = [];
    },

    async createTag() {
      if (this.tag === "") {
        this.$toasted.error("tag フィールドは必須です。");
      } else {
        const formData = {
          user_id: localStorage.getItem("user_id"),
          shop_id: this.shop_id,
          tag_name: this.tag,
        };
        await this.createNewTag(formData);
        await this.getListTag(this.shop_id);
        const datatag = this.listTagOptions[0];
        this.$set(datatag, "checked", true);
        this.addTag(datatag);
        this.tag = "";
      }
    },

    addTag(item) {
      if (this.selectedTag.length === 0) {
        this.selectedTag.push({
          tag_name: item.tag_name,
          tag_id: item.tag_id,
          checked: true,
        });
      } else {
        this.selectedTag.map((value) => {
          if (value.tag_id === item.tag_id) {
            this.isAdded++;
          }
        });
        if (this.isAdded === 0) {
          this.selectedTag.push({
            tag_name: item.tag_name,
            tag_id: item.tag_id,
            checked: true,
          });
          this.isAdded = 0;
        } else {
          this.isAdded = 0;
        }
      }
    },

    removeTag(event) {
      this.index = this.selectedTag.indexOf(event.target);
      this.selectedTag.splice(event, 1);
    },

    nextStep() {
      if (this.listCata.length > this.$route.idPage) {
        this.$router
          .push({
            name: "DetailPageLibrary",
            params: {
              idPage: this.$route.idPage++,
              idContent: this.$route.idContent,
            },
          })
          .catch(() => {});
      }
    },

    prevStep() {},
    handleScroll: function (evt, el) {
      var limit = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      if (window.scrollY > 0) {
        el.setAttribute(
          "style",
          "position: fixed; top: 140px; left:0; transition: ease-in-out;transition: all 0.05s;z-index: 99;"
        );
        // eslint-disable-next-line prettier/prettier
        el.style.height = window.offsetHeight - 140 + window.scrollY + "px";
        let topOj = el.offseTop;
        topOj = 140 - window.scrollY + "px";
        el.style.top = parseInt(topOj) < 0 ? "0px" : topOj;
      } else {
        el.setAttribute(
          "style",
          "position: fixed; top: 140px; left:0; transition: ease-in-out;transition: all 0.05s;z-index: 99; height: calc(100vh - 140px)"
        );
      }

      return window.scrollY > limit;
    },
    handleScrollTitle: function (evt, el) {
      var limit = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      if (window.scrollY < 140) {
        el.setAttribute("style", "padding: 30px 50px");
      } else if (window.scrollY > 140) {
        el.setAttribute("style", "padding: 30px 50px 30px 150px");
      }
      return window.scrollY > limit;
    },
    getTimeEmbed() {
      eventBus.$emit("getCurrtentTimeYoutu");
      if (localStorage.getItem("pdfView")) {
        this.page_pdf = 0;
      } else {
        this.page_pdf = localStorage.getItem("pdfView");
      }
    },
    selectedListNote(selected) {
      this.isShow = true;
      if (selected === 0) {
        this.isListNote = 1;
      } else {
        this.isListNote = 0;
        this.isShow = false;
      }
      if (this.isCategoryTitle === 1) {
        this.isCategoryTitle = 0;
      }
    },
    selectedCategoryTitle(selected) {
      this.isShow = true;
      if (selected === 0) {
        this.isCategoryTitle = 1;
      } else {
        this.isCategoryTitle = 0;
        this.isShow = false;
      }
      if (this.isListNote === 1) {
        this.isListNote = 0;
      }
    },
    ClickContentSelected(index) {
      if (this.isSelect === index) {
        this.isSelect = null;
      } else {
        this.isSelect = index;
      }
    },
    ClickTagChecked(item) {
      if (item.checked === true) {
        this.$set(item, "checked", false);
        this.selectedTag = this.selectedTag.filter(
          (ele) => ele.tag_id !== item.tag_id
        );
      } else {
        this.$set(item, "checked", true);
        this.selectedTag.push({
          tag_name: item.tag_name,
          tag_id: item.tag_id,
        });
      }
    },
    backBottom() {
      this.isShowListNote = 0;
      // this.$store.commit("set", [
      //   "isShowButtonChatOrNote",
      //   !this.isShowButtonChatOrNote,
      // ]);
    },
    async createNoteIcon() {
      if (this.contentIcon === "") {
        this.$toasted.error("コンテンツノートは入力必須項目です。");
      } else {
        let shop_id = this.shop_id;
        const { params } = this.$route;
        const formData = {
          shop_id: shop_id,
          content_note: this.contentIcon,
          content_id: parseInt(params.idContent),
          page_id: parseInt(this.idPageView),
        };
        await this.createNote(formData);
        this.contentIcon = "";
      }
    },
    showModalEdit(id) {
      this.tag = "";
      this.title = "";
      this.content = "";
      // this.$store.commit("set", ["IdContent", id]);
      this.selectedTag = [];
      const shop_id = this.shop_id;
      this.getListTag(shop_id);
      this.getNoteByIdUser(id);
      this.$bvModal.show("modal-edit-note-mobile1");
    },
    closeModalEdit() {
      this.$bvModal.hide("modal-edit-note-mobile1");
      this.selectedTag = [];
    },
    selectScreen(value) {
      this.isShowListNote = 1;
      this.isCreenMobile = value;
      if (value === 1) {
        this.getListNoteByPages();
      } else {
        this.getListChats(this.page);
      }
    },
    async getListChats(page) {
      if (page === undefined) {
        page = this.page;
      }
      const formData = {
        page: page,
        data: {
          shop_id: parseInt(this.shop_id),
          content_id: this.$route.params.idContent,
          limit: 10,
        },
      };
      await this.getListChat(formData);
    },
    async getDetailMessage(item) {
      this.isShowDetailChat = 1;
      const dataRequest = {
        idChat: item.id,
        shop_id: this.shop_id,
      };
      await this.getMessageByChatID(dataRequest);
    },
    async ClickSendMessage() {
      if (!this.titleSendMessage) {
        return;
      }
      const data = {
        shop_id: this.shop_id,
        chat_id: this.idChat,
        message: this.titleSendMessage,
        user_id: localStorage.getItem(Constants.USER_ID),
      };
      const dataRepond = await this.sendMessage(data);
      if (dataRepond.success) {
        this.titleSendMessage = null;
        const dataRequest = {
          id: this.idChat,
        };
        await this.getDetailMessage(dataRequest);
      }
    },
    async deletMessage(id) {
      const dataRequest = {
        id: id,
        shop_id: this.shop_id,
      };
      const dataRepond = await this.deleteMessageByID(dataRequest);
      if (dataRepond.success) {
        this.titleSendMessage = null;
        const dataRequest = {
          id: this.idChat,
        };
        await this.getDetailMessage(dataRequest);
      }
    },
    async backListMenu() {
      this.isShowDetailChat = 0;
      const page = this.page;
      const formData = {
        page: page,
        data: {
          shop_id: parseInt(this.shop_id),
          content_id: this.$route.params.idContent,
          limit: 10,
        },
      };
      await this.getListChat(formData);
    },
    checkLimitLength(event) {
      let newValue = event.target.value;
      if (newValue.length > Constants.LIMIT_MESSAGE) {
        event.preventDefault();
        this.titleSendMessage = this.previousTitleSendMessage;
        this.$store.commit("set", ["message", "メッセージが長すぎます"]);
        this.$store.commit("set", ["error", true]);
      } else {
        this.titleSendMessage = newValue;
        this.previousTitleSendMessage = newValue;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sidebarTtl {
  width: 100%;
  height: 81px;
  font-size: 20px;
  font-weight: normal;
  padding: 30px 0;
  border-bottom: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  position: relative;
  line-height: 1;
  z-index: 11;
  text-align: center;
}
.isSelected {
  background: #f5f5f5;
}
.customA {
  background: #eee !important;
  border: 1px solid #eee !important;
  border-right: none !important;
  color: #1d1d1d !important;
  transition: all 0.3s ease !important;
}
.customA :hover {
  background: #eee;
  border-right: none;
  color: #1d1d1d;
  transition: all 0.3s ease;
  cursor: pointer;
}
.customA ::before {
  content: "";
  display: block;
  width: 18px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 18px 20px;
  position: absolute;
  top: -1px;
  left: 100%;
  transition: 0.2s ease-out;
}
.bottom-navbar {
  z-index: 1039 !important;
}
.customSpan {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 235px;
  display: block;
  height: 18px;
  line-height: 18px;
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ",
    Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  word-break: break-all;
  box-sizing: border-box;
  font-size: 11px;
  padding: 0 0 0 10px;
}
/deep/ .modalCustomMobile > .modal-dialog {
  max-width: auto !important;
  margin: 1.75rem auto;
}
/deep/ .modalCustomMobile > .modal-dialog > .modal-content > .modal-body {
  overflow: auto;
  height: 465px;
}
.customTag2 {
  background: #fff;
  border: 1px solid #eee;
  border-right: none;
  color: #1d1d1d;
  transition: all 0.3s ease;
}
.customContentNoteMobile {
  @media (max-width: 450px) {
    width: 95%;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
.customListNote {
  padding: 3px 15px;
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  background: #fff;
}
.customContentMoblie {
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #d1d1d1;
  padding: 15px 0 15px 15px;
  /* background-image: url(/img/icon_arrow01.939c2d50.png); */
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 9px;
  border-radius: 20px;
  box-shadow: 0px 0px 11px 0px rgba(125, 125, 125, 0.75);
  -webkit-box-shadow: 0px 0px 11px 0px rgba(125, 125, 125, 0.75);
  -moz-box-shadow: 0px 0px 11px 0px rgba(125, 125, 125, 0.75);
}
.checkPopupContent {
  height: 439px !important;
}
.marginModalNote {
  margin-top: 61px;
}
.customButtonChaAndNote {
  background: #fffde7;
  z-index: 1020 !important;
}
.customTitleContent {
  margin: 0 auto;
  width: 200px;
  padding: 20px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customTitle {
  cursor: pointer;
  border-bottom: 1px solid #d1d1d1;
  padding: 30px 0 30px 20px;
  background-image: url(../../assets/img/icon_arrow01.png);
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 9px;
}
.customChatUser {
  width: 250px;
  border-radius: 25px;
  background-color: white;
}
.customChatAdmin {
  width: 250px;
  border-radius: 25px;
  background-color: white;
}
.zindexListChat {
  z-index: 1000 !important;
}
.zindexDetailChat {
  z-index: 1015 !important;
}
.bottom-navbarUI {
  width: 100%;
  height: 45px;
}
</style>
